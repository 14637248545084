import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwTeenSchoolGlance = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>BMW TEEN SCHOOL AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>Where does the BMW Teen School take place?</h5>
                <p>We currently have two locations in California and South Carolina. Please visit our <a href="/locations">locations page</a> for more information.</p>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>1155 SC-101, Greer, SC 29651<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>BMW Performance Center West</h5>
                <p>86-050 Jasper Lane, Thermal, CA 92274<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Is the BMW Performance Center affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
              <div>
                <h5>Does the BMW Teen School make a great vacation?</h5>
                <p>There’s a lot to do in the areas surrounding Greer and Palm Springs. Please visit our <a href="/locations">locations page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Is BMW Teen School a high-performance driving event?</h5>
                <p>
                  Yes. Your teen will be driving our BMW M340i, which features nearly 400 horsepower. Each event takes place on a closed course under instructor guidance.
                </p>
              </div>
              <div>
                <h5>How old do you need to be to participate in BMW Driver’s School?</h5>
                <p>
                  You must be at least 15, with a valid permit, for BMW Teen Driving School.
                </p>
              </div>
              <div>
                <h5>What can parents do on-site?</h5>
                <p>
                  We have an M Café, Wi-Fi and Gift Shop on-site. You can also register and participate in one of our BMW Driver’s School or BMW M Programs if they happen to run concurrently.
                </p>
              </div>
              <div>
                <h5>BMW Teen Driver’s School Gift</h5>
                <p>
                  You can always give a gift card to BMW Teen Driving School and other programs, visit our <a href="/giftcards">Gift Card</a> page for more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwTeenSchoolGlance;